import { useEffect, FC } from 'react';

const useRespVoice = (url: string) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = url;
    console.log('url of responsive voice : ', url);
    script.async = true;
    script.id = 'customJsId';
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, [url]);
};

export default useRespVoice;
