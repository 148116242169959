import React, { FC, useEffect, useState } from 'react';
import clsx from 'clsx';
import { Route, Routes, Link } from 'react-router-dom';
import style from './EverydayTask.module.scss';
import { useDispatch, useSelector } from '../..';
import Task from '../../components/Task/Task';
import { getbySourceThunk, getDailyTasksThunk, getUserFavsThunk } from '../../services/action-handlers/mainCreator';


const EverydayTask: FC = () => {
    const dispatch = useDispatch();
    const [isLessons, setIsLes] = useState(true);
    const [directin, setDirectn] = useState<string>('toCh');
    const [btnClick, setBtnClick] = useState<number>(0);

    const { currentTasks, userFavs } = useSelector((state) => ({
      currentTasks: state.main.receivedCh,
      userFavs: state.auth.user.favsDaily,
    }));

    const loadList = (e: any) => {
        const taskNumber = e.target.name;
        console.log('e: ', taskNumber);
        if (taskNumber === 'favs') {
          dispatch(getUserFavsThunk(userFavs));
        } else if (taskNumber === 'begcomp')
          {
            dispatch(getbySourceThunk({src: taskNumber}));
          } else {
        dispatch(getDailyTasksThunk({ qty: taskNumber }));
        }
      };


  return (
    <div className={style.container}>
      <div className={style.innerBox}>
      <section className={clsx(style.postsection, style.btns)}>
        <button
          className={style.btn}
          onClick={loadList}
          type='button'
          name='10'
        >
          Load 10
        </button>
        <button
          className={style.btn}
          onClick={loadList}
          type='button'
          name='20'
        >
          Load 20
        </button>
        <button
          className={clsx(style.btn, style.starred)}
          onClick={loadList}
          type='button'
          name='favs'
        >
          Избранное
        </button>
        <button
          className={clsx(style.btn, style.starred)}
          onClick={loadList}
          type='button'
          name='begcomp' // cat = beginner comprehensive
        >
          Begin Compreg
        </button>
        {/* sample <ul className={style.tasks}>
          <Task
            btn={btnClick}
            direction={directin}
            index={64674333}
            task={{
              lessonTask: 'en task',
              lessonChinese: 'chin sample',
              lessonPinyin: 'pin sample',
              id: 'sfdlj89we',
            }}
          />
          </ul> */}
      </section>
      <section
        className={clsx(
          style.postsection,
          currentTasks.length > 0 ? style.visible : style.hidden
        )}
      >
        {currentTasks.length > 0 ? (
          <ul className={style.tasks}>
            {currentTasks.map((task: any, index: any) => (
              <Task
                key={task.id}
                btn={btnClick}
                direction={directin}
                index={index}
                task={{
                  lessonTask: task.taskEn,
                  lessonChinese: task.chin,
                  lessonPinyin: task.pinyin,
                  id: task.id,
                }}
              />
            ))}
          </ul>
        ) : (
          ''
        )}
      </section>
      </div>
    </div>
  );
};

export default EverydayTask;
