import React, { FC } from 'react';
import {
  Route, Navigate, RouteProps, Outlet,
} from 'react-router-dom';
import { useSelector } from '../..';

const ProtRouteAuth: FC<RouteProps> = ({ children, ...rest }) => {
  const { path } = { ...rest };
  
  const { isLogged, isTokenPending } = useSelector((state) => ({
    isLogged: state.auth.isLogged,
    isTokenPending: state.auth.isTokenRequesting,
  }));
  console.log('islogged in auth: ', isLogged);

  return !isLogged ? <Outlet /> : <Navigate to="/" />;
};

export default ProtRouteAuth;
