import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { useDispatch, useSelector } from "../../..";
import style from "./Login.module.scss";
import { loginFunctionThunk } from "../../../services/action-handlers/authCreator";
import useFormAndValidation from "../../../hooks/useForm";
import { initialFormLogin, successEntr } from "../../../utils/constants";
import showicon from "../../../images/view.svg";
import hideicon from "../../../images/hide.svg";

const Login: FC = () => {
  const dispatch = useDispatch();
  const { isLogged, submitError, maintanence } = useSelector((state) => ({
    isLogged: state.auth.isLogged,
    submitError: state.auth.submitError,
    maintanence: state.site.maintanence,
  }));

  const [isPassShown, setIsPassShown] = useState<boolean>(false);
  // const captchaRef = useRef<any>(null);
  const { values, handleChange, errors, validities, isValid } =
    useFormAndValidation(initialFormLogin);

  useEffect(() => {
    if (submitError !== successEntr) {
      values.password = initialFormLogin.values.password;
    }
  }, [submitError]);

  // recap data
  /* 
  const [token1, setToken1] = useState("");
  const verifyRecaptchaCallback = React.useCallback((tokenne: any) => {
    setToken1(tokenne);
  }, []); */
  const toggleShowPass = (e: any) => {
    e.preventDefault();
    setIsPassShown(!isPassShown);
  };

  const submitLogin = (e: any) => {
    e.preventDefault();
    dispatch(
      loginFunctionThunk({ email: values.email, password: values.password })
    );
  };

  /* 
    const setRegisterNull = () => {
      dispatch(makeIsRegFalse());
    };
    */

  // <Link onClick={/* setRegisterNull */} to="/signup">&#8592; Вернуться к регистрации</Link>

  return (
    <div className={style.container}>
      <div className={style.innerBox}>
        <div>
          {!maintanence ? (
            <Link to="/signup">&#8592; Вернуться к регистрации</Link>
          ) : (
            <Link to="/">&#8592; Вернуться на главную</Link>
          )}
        </div>
        <h2>Вход (пользователь уже существует)</h2>

        <form className={style.form} onSubmit={submitLogin}>
          <input
          autoCorrect="off"
            className={style.input}
            name="email"
            onChange={handleChange}
            type="email"
            value={values.email}
          />
          <div className={style.form__element}>
            <input
            autoCorrect="off"
              className={style.input}
              name="password"
              onChange={handleChange}
              type={isPassShown ? "text" : "password"}
              value={values.password}
            />            
            <button
              type="button"
              className={style.form__iconbtn}
              onClick={(e) => toggleShowPass(e)}
            >
              {isPassShown ? (
                <img className={style.icon} src={hideicon} alt="hide pass" />                
              ) : (
                <img className={style.icon} src={showicon} alt="show pass" />
              )}
            </button>
            <p
              className={clsx(
                style.parMessage,
                submitError === successEntr ? style.parMessage_success : ""
              )}
            >
              {submitError}
            </p>
          </div>
          <div className={style.form__element}>
            <input
              className={style.button}
              disabled={!isValid}
              onClick={submitLogin}
              type="submit"
              value="Войти"
            />
          </div>

          <div>
            {/* <GoogleReCaptcha
                onVerify={verifyRecaptchaCallback}
              /> */}
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
