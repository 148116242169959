
export const starredFormed = [
    'ch-53',
    'ch-45',
    'ch-40',
    'ch-30',
    'ch-59',
    'ch-25',
    'ch-61',
    'ch-35',
    'ch-46',
    'ch-9',
    'ch-68',
    'ch-62',
    'ch-47',
    'ch-66',
    'ch-32',
    'ch-48',
    'ch-56',
    'ch-7',
    'ch-15',
    'ch-67',
    'ch-33',
  ];

export const characterCats = ['teacher-dev-cat-2', 'teacher-dev-cat-3'];