import React, { FC, useEffect, useState } from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from '../../..';
import useFormAndValidation from '../../../hooks/useForm';
import style from './Signup.module.scss';
import { initialFormRegister, successEntr } from '../../../utils/constants';
import { loginFunctionThunk, signupFunctionThunk } from '../../../services/action-handlers/authCreator';

const Signup: FC = () => {
  const dispatch = useDispatch();

  const { isRegistered, submitError } = useSelector((state) => ({
    isRegistered: state.auth.isRegistered,
    submitError: state.auth.submitError,
  }));

  // recap data
  const [chosenLang, setChosenLang] = useState<string>('deu');

  const setLang = (e: any) => {
    const { id } = e.target;
    setChosenLang(id);
  };

  const {
    values, handleChange, errors, validities, isValid,
  } = useFormAndValidation(initialFormRegister);

  useEffect(() => {
    if (isRegistered) {
        /*
      dispatch(
        loginFunctionThunk({ email: values.email, password: values.password }),
      );
      */
    }
  }, [isRegistered]);

  // TODO: change radio buttons to correct models names,
  // since now on sign up there's langs of base1, base2


  const submitRegister = (e: any) => {
    e.preventDefault();

      const dataFromForm = {
        email: values.email,
        password: values.password,
        name: values.name,
      };
    dispatch(
        signupFunctionThunk(dataFromForm),
      ); 
    
  };

  return (
    <div className={style.container}>
      <div className={style.innerBox}>
        <h2>Регистрация нового пользователя</h2>
        <form className={style.form} onSubmit={submitRegister}>
        <input
            className={style.input}
            name='name'
            onChange={handleChange}
            type='text'
            autoCorrect="off"
            value={values.name}
          />
          <input
            className={style.input}
            name='email'
            onChange={handleChange}
            type='text'
            autoCorrect="off"
            value={values.email}
          />
          <input
            className={style.input}
            name='password'
            onChange={handleChange}
            type='password'
            autoCorrect="off"
            value={values.password}
          />
          <div>
            {/* <GoogleReCaptcha
              onVerify={verifyRecaptchaCallback}
            /> */} 
          </div>
          <p className={clsx(
            style.parMessage,
            submitError === successEntr
              ? style.parMessage_success : '',
          )}
          >
            {submitError}
          </p>
          <input
            className={style.button}
            disabled={!isValid}
            onClick={submitRegister}
            type='submit'
            value='Зарегистрироваться'
          />
        </form>
      </div>
    </div>
  );
};

export default Signup;
