import React, { FC, useEffect, useState } from "react";
import clsx from "clsx";
import style from "./Adminpanel.module.scss";
import useFormAndValidation from "../../hooks/useForm";
import { useDispatch, useSelector } from "../..";
import { initialPanel } from "../../utils/constants";
import {
  addnewCharacThunk,
  addnewThunk,
} from "../../services/action-handlers/mainCreator";
import { clearMessage } from "../../services/mainReducer";
import SentencForm from "./forms/SentencForm/SentencForm";
import CharactForm from "./forms/CharactForm/CharactForm";

const Adminpanel: FC = () => {
  const dispatch = useDispatch();

  const { addInfo, addCharacInfo } = useSelector((state) => ({
    addInfo: state.main.addInfo,
    addCharacInfo: state.main.addCharacInfo,
  }));

  useEffect(() => {
    if (addInfo) {
      setTimeout(() => dispatch(clearMessage()), 3000); // make it smoother
    }
  }, [addInfo]);

  return (
    <div className={style.container}>
      <div className={style.innerBox}>
        <section className={clsx(style.postsection, style.btns)}>
          <SentencForm />
        </section>

        {/* characters form */}
        <section className={clsx(style.postsection, style.second)}>
          <CharactForm />
        </section>
      </div>
    </div>
  );
};
export default Adminpanel;
