import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import App from "./components/App/App";
import { BrowserRouter } from "react-router-dom";
import { configureStore, ThunkDispatch } from "@reduxjs/toolkit";
import {
  TypedUseSelectorHook,
  useSelector as selectorHook,
  useDispatch as dispatchHook,
  Provider,
} from "react-redux";
import { TAppActions } from "./types/types";
import rootReducer from "./services";

// start setting up stores and dispatches
const store = configureStore({
  reducer: rootReducer,
});

type RootState = ReturnType<typeof store.getState>;

const useSelector: TypedUseSelectorHook<RootState> = selectorHook;

export type AppDispatch = ThunkDispatch<RootState, never, TAppActions>;
const useDispatch = () => dispatchHook<AppDispatch>();

export { useSelector, useDispatch };
// end setting up stores and dispatches

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <Provider store={store}>
    <React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.StrictMode>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
