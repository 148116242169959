import { apiConfig } from "../constants";

const _getResponse = (data: any) => {
    if (data.ok) {
      return data.json();
    }
    return Promise.reject(`Ошибка такова: ${data.status}`);
  };

export const fetchApiDaily = ({ qty }: { qty: string }) => fetch(`${apiConfig.url}/getdaily/${qty}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json; charset=UTF-8',
    },
  }).then(_getResponse);

  export const addNewSentence = (gotData: any) => fetch(`${apiConfig.url}/cards/add`, {
    method: "POST",
    headers: apiConfig.headers,
    body: JSON.stringify(gotData),
  }).then(_getResponse);

  export const getUserFavs = (favslist: any) => fetch(`${apiConfig.url}/cards/getFavsDaily`, {
    method: "POST",
    headers: apiConfig.headersRefresh,
    body: JSON.stringify(favslist),
  }).then(_getResponse);
  

  // Characters: 
  export const addNewCharacter = (gotData: any) => fetch(`${apiConfig.url}/character/add`, {
    method: "POST",
    headers: apiConfig.headers,
    body: JSON.stringify(gotData),
  }).then(_getResponse);

  export const fetchApiChatacter = ({ qty }: { qty: string }) => fetch(`${apiConfig.url}/characters/${qty}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json; charset=UTF-8',
    },
  }).then(_getResponse);

  export const getCardsbySource = ({ src }: { src: string }) => fetch(`${apiConfig.url}/cards/getbysource/${src}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json; charset=UTF-8',
    },
  }).then(_getResponse);

  