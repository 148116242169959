import { getCookie, setCookie } from "./apis/cookiehandler";

const accessToken = getCookie("accessTemp"); // accessTemp needed
const refreshToken = getCookie("chinesetoken");

export const apiConfig = {
    // server PHP or Express.js:
    url: 'https://api-ch.ynstudios.ru', // PHP
    // url: 'http://localhost:3000', // EXPRESS.JS
    headers: {
      'Content-Type': 'application/json',
    },
    headersAccess: { 
      'Content-Type': 'application/json',
       'Authorization': accessToken ? 'Bearer ' + accessToken : ''},
    headersRefresh: { 
        'Content-Type': 'application/json',
         'Authorization': refreshToken ? refreshToken : ''},

  };

  export const _getResponse = (data: any) => data.json();
  
  export const successEntr = 'Успешный вход.';

  export const initialFormRegister = {
    values: {
      email: '',
      password: '',
      name: '',
    },
    errors: {
      email: '',
      password: '',
      name: '',
    },
    validities: {
      email: true,
      password: true,
      name: true,
    },
  };


  export const initialFormLogin = {
    values: {
      email: "",
      password: "",
    },
    errors: {
      email: "",
      password: "",
    },
    validities: {
      email: false,
      password: false,
    },
  };
  export const initialPanel = {
    values: {
      id: "",
      source: "begcomp",
      taskEn: "", 
      pinyin: "",
      chin: "",
    },
    errors: {
      id: "",
      source: "",
      taskEn: "", 
      pinyin: "",
      chin: "",
    },
    validities: {
      id: false,
      source: false,
      taskEn: false, 
      pinyin: false,
      chin: false,
    },
  };

  
  export const voiceLink = 'https://code.responsivevoice.org/responsivevoice.js?key=F0Kp6VBk';

  export const setCookiesAll = (payload: any) => {
    setCookie('accessTemp', payload.accessToken.split(' ')[1], { expires: 60*60*24*14 }); //2 minutes live - make it longer not to jump out lets make it 2 weeks
    setCookie('chinesetoken', payload.refreshToken, { expires:  60*60*24*20 }); // set cookie in front for 1 day 3 weeks
  }